import { provideRouter } from '@angular/router';

import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, isDevMode, provideExperimentalZonelessChangeDetection } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { DEFAULT_APP_LANGUAGES, DEFAULT_LANGUAGE } from '@configs/constants.config';
import { NGX_EDITOR_CONFIG } from '@configs/ngx-editor.config';
import { provideTransloco } from '@jsverse/transloco';
import { TranslocoHttpLoader } from '@loaders/transloco-loader';
import { APP_INTERCEPTORS } from '@providers/app-interceptors';
import { APP_PROVIDERS } from '@providers/app-providers';
import { routes } from '@routes/_app.routes';
import { NgxEditorModule } from 'ngx-editor';
import { provideQuillConfig } from 'ngx-quill';

import '@angular/common/locales/global/de';
import '@angular/common/locales/global/en';
import '@angular/common/locales/global/pl';

export const appConfig: ApplicationConfig = {
  providers: [
    provideExperimentalZonelessChangeDetection(),
    provideRouter(routes),
    provideHttpClient(withInterceptors(APP_INTERCEPTORS)),
    provideAnimationsAsync(),
    provideTransloco({
      config: {
        availableLangs: DEFAULT_APP_LANGUAGES,
        defaultLang: DEFAULT_LANGUAGE,
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideQuillConfig({
      modules: {},
    }),
    importProvidersFrom(
      NgxEditorModule.forRoot({
        icons: NGX_EDITOR_CONFIG.icons,
        locals: NGX_EDITOR_CONFIG.locals,
      })
    ),
    APP_PROVIDERS,
  ],
};
