import { Routes } from '@angular/router';
import { AppRoutes } from '@configs/routes.config';
import { AuthRoute } from './auth.route';
import { DashboardRoute } from './dashboard.route';

export const routes: Routes = [
  AuthRoute,
  DashboardRoute,
  {
    path: AppRoutes.Blank,
    redirectTo: AppRoutes.Dashboard,
    pathMatch: 'full',
  },
  {
    path: AppRoutes.Wildcard,
    redirectTo: AppRoutes.Blank,
  },
];
