import { Route } from '@angular/router';
import { AppRoutes } from '@configs/routes.config';
import { AuthLayoutComponent } from '@layouts/auth-layout/auth-layout.component';

const LOGIN_PAGE = () => import('@pages/auth/_pages/login/login.component').then(m => m.LoginComponent);

const REMIND_PASSWORD_PAGE = () => import('@pages/auth/_pages/remind-password/remind-password.component').then(m => m.RemindPasswordComponent);

const RESET_PASSWORD_PAGE = () => import('@pages/auth/_pages/reset-password/reset-password.component').then(m => m.ResetPasswordComponent);

const SET_PASSWORD_PAGE = () => import('@pages/auth/_pages/set-password/set-password.component').then(m => m.SetPasswordComponent);

const VERIFY_PAGE = () => import('@pages/auth/_pages/verify/verify.component').then(m => m.VerifyComponent);

export const AuthRoute: Route = {
  path: AppRoutes.Auth,
  component: AuthLayoutComponent,
  children: [
    {
      path: AppRoutes.Login,
      loadComponent: LOGIN_PAGE,
    },
    {
      path: AppRoutes.RemindPassword,
      loadComponent: REMIND_PASSWORD_PAGE,
    },
    {
      path: AppRoutes.ResetPassword,
      loadComponent: RESET_PASSWORD_PAGE,
    },
    {
      path: AppRoutes.SetPassword,
      loadComponent: SET_PASSWORD_PAGE,
    },
    {
      path: AppRoutes.Verify,
      loadComponent: VERIFY_PAGE,
    },
    {
      path: AppRoutes.Blank,
      redirectTo: AppRoutes.Login,
      pathMatch: 'full',
    },
  ],
};
