import { Routes } from '@angular/router';
import { AppRoutes } from '@configs/routes.config';
import { EmailFootersPageGuard } from '@guards/email-footers-page.guard';

const AGENT_ABOUT = () => import('@pages/dashboard/agent/_pages/agent-about/agent-about.component').then(m => m.AgentAboutComponent);

const AGENT_LOGS = () => import('@pages/dashboard/agent/_pages/agent-logs/agent-logs.component').then(m => m.AgentLogsComponent);

const AGENT_EMAIL_FOOTERS = () =>
  import('@pages/dashboard/agent/_pages/agent-email-footers/agent-email-footers.component').then(m => m.AgentEmailFootersComponent);

export const AgentRoutes: Routes = [
  {
    path: AppRoutes.Agent.Details,
    children: [
      {
        path: AppRoutes.Agent.About,
        loadComponent: AGENT_ABOUT,
        title: 'About agent',
      },
      {
        path: AppRoutes.Agent.EmailFooters,
        loadComponent: AGENT_EMAIL_FOOTERS,
        title: 'Email footers',
        canActivate: [EmailFootersPageGuard],
      },
      {
        path: AppRoutes.Agent.Logs,
        loadComponent: AGENT_LOGS,
        title: 'Logs',
      },
      {
        path: AppRoutes.Blank,
        redirectTo: AppRoutes.Agent.About,
        pathMatch: 'full',
      },
    ],
  },
];
