import { Routes } from '@angular/router';
import { AppRoutes } from '@configs/routes.config';

const TICKETS_LIST = () => import('@pages/dashboard/tickets/_pages/tickets-list/tickets-list.component').then(m => m.TicketsListComponent);

export const TicketsRoutes: Routes = [
  {
    path: AppRoutes.Tickets.Page,
    loadComponent: TICKETS_LIST,
  },
  {
    path: AppRoutes.Blank,
    redirectTo: 'inbox',
    pathMatch: 'full',
  },
];
