import { NgxEditorConfig } from 'ngx-editor';

const ICONS_PATH = 'assets/images/ngx-editor-icons';

export const NGX_EDITOR_CONFIG: NgxEditorConfig = {
  icons: {
    bold: `<img src='${ICONS_PATH}/bold.svg'/>`,
    italic: `<img src='${ICONS_PATH}/italic.svg'/>`,
    underline: `<img src='${ICONS_PATH}/underline.svg'/>`,
    bullet_list: `<img src='${ICONS_PATH}/bullet_list.svg'/>`,
    text_color: `<img src='${ICONS_PATH}/text_color.svg'/>`,
    link: `<img src='${ICONS_PATH}/link.svg'/>`,
  },
  locals: {
    bold: 'Boldddddd',
    italic: 'Italic',
    underline: 'Underline',
    bullet_list: 'Bullet List',
    text_color: 'Text Color',
    link: 'Link',
  },
};
