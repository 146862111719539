import { Route } from '@angular/router';
import { AppRoutes } from '@configs/routes.config';
import { OnlyAdminPageGuard } from '@guards/only-admin-page.guard';

const SETTINGS_PAGE = () => import('@pages/dashboard/settings/settings.component').then(m => m.SettingsComponent);

const PRIORITIES_PAGE = () => import('@pages/dashboard/settings/_pages/priorities/priorities.component').then(m => m.PrioritiesComponent);

const BRANDS_PAGE = () => import('@pages/dashboard/settings/_pages/brands/brands.component').then(m => m.BrandsComponent);

const DEPARTMENTS_PAGE = () => import('@pages/dashboard/settings/_pages/departments/departments.component').then(m => m.DepartmentsComponent);

const DASHBOARD_SETTINGS_PAGE = () =>
  import('@pages/dashboard/settings/_pages/dashboard-settings/dashboard-settings.component').then(m => m.DashboardSettingsComponent);

const PERSONALIZATION_PAGE = () => import('@pages/dashboard/settings/_pages/personalization/personalization.component').then(m => m.PersonalizationComponent);

const NOTIFICATIONS_PAGE = () => import('@pages/dashboard/settings/_pages/notifications/notifications.component').then(m => m.NotificationsComponent);

const CHANNELS_PAGE = () => import('@pages/dashboard/settings/_pages/channels/channels.component').then(m => m.ChannelsComponent);

const CHANNELS_LIST_PAGE = () =>
  import('@pages/dashboard/settings/_pages/channels/_pages/channels-list/channels-list.component').then(m => m.ChannelsListComponent);

const CREATE_CHANNEL_PAGE = () =>
  import('@pages/dashboard/settings/_pages/channels/_pages/create-channel/create-channel.component').then(m => m.CreateChannelComponent);

const PROFILE_PAGE = () => import('@pages/dashboard/settings/_pages/profile/profile.component').then(m => m.ProfileComponent);

const PASSWORD_PAGE = () => import('@pages/dashboard/settings/_pages/password/password.component').then(m => m.PasswordComponent);

export const SettingsRoute: Route = {
  path: AppRoutes.Settings,
  loadComponent: SETTINGS_PAGE,
  children: [
    {
      path: AppRoutes.DashboardSettings,
      loadComponent: DASHBOARD_SETTINGS_PAGE,
      title: 'Dashboard settings',
    },
    {
      path: AppRoutes.Personalization,
      loadComponent: PERSONALIZATION_PAGE,
      title: 'Personalization',
    },
    {
      path: AppRoutes.Notifications,
      loadComponent: NOTIFICATIONS_PAGE,
      title: 'Notifications',
    },
    {
      path: AppRoutes.Departments,
      loadComponent: DEPARTMENTS_PAGE,
      title: 'Departments',
    },
    {
      path: AppRoutes.Priorities,
      loadComponent: PRIORITIES_PAGE,
      title: 'Priorities',
    },
    {
      path: AppRoutes.Brands,
      loadComponent: BRANDS_PAGE,
      title: 'Brands',
      canActivate: [OnlyAdminPageGuard],
    },
    {
      path: AppRoutes.Channels,
      loadComponent: CHANNELS_PAGE,
      title: 'Channels',
      canActivate: [OnlyAdminPageGuard],
      children: [
        {
          path: AppRoutes.ChannelsList,
          loadComponent: CHANNELS_LIST_PAGE,
          title: 'Channels',
        },
        {
          path: AppRoutes.CreateChannel,
          loadComponent: CREATE_CHANNEL_PAGE,
          title: 'Create channel',
        },
        {
          path: AppRoutes.Blank,
          redirectTo: AppRoutes.ChannelsList,
          pathMatch: 'full',
        },
      ],
    },
    {
      path: AppRoutes.Profile,
      loadComponent: PROFILE_PAGE,
      title: 'Profile',
    },
    {
      path: AppRoutes.Password,
      loadComponent: PASSWORD_PAGE,
      title: 'Password',
    },
    {
      path: AppRoutes.Blank,
      redirectTo: AppRoutes.DashboardSettings,
      pathMatch: 'full',
    },
  ],
};
