<div class="layout">
  <div class="layout__video">
    <vg-player (onPlayerReady)="onVideoReady($event)" class="video-player">
      <video #loginVideo [muted]="true" [vgMedia]="$any(loginVideo)" id="login-video" preload="auto">
        <source src="assets/videos/login.webm" type="video/webm" />
      </video>
    </vg-player>
  </div>
  <div class="layout__content">
    <div class="layout__content-wrapper">
      <h1 class="content-wrapper__header">
        {{ '_AuthLayout.Welcome to' | transloco }}
        <img [defaultImage]="PLACEHOLDER_IMAGE_128x128" [lazyLoad]="LOGO_PATH" alt="{{ '_Global.Brand logo' | transloco }}" />
        !
      </h1>
      <div class="content-wrapper__form">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
