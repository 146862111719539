import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AppRoutes } from '@configs/routes.config';
import { StorageService } from '@services/storage.service';

export const AuthorizedUserGuard: CanActivateFn = (route, state) => {
  const storageService = inject(StorageService);
  const router = inject(Router);

  if (storageService.isUserLoggedIn()) {
    storageService.user.set(storageService.getUser());
    return true;
  }

  router.navigate([AppRoutes.Default, AppRoutes.Auth, AppRoutes.Login]);
  return false;
};
