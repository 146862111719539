import { Routes } from '@angular/router';
import { AppRoutes } from '@configs/routes.config';

const AGENTS_LIST = () =>
  import('@pages/dashboard/agents/_pages/agents-list/agents-list.component').then(m => m.AgentsListComponent);

export const AgentsRoutes: Routes = [
  {
    path: AppRoutes.Tickets.Page,
    loadComponent: AGENTS_LIST,
  },
  {
    path: AppRoutes.Blank,
    redirectTo: 'all',
    pathMatch: 'full',
  },
];
