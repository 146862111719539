import { setDefaultOptions } from 'date-fns';
import { DATE_FNS_CONFIG } from '@configs/date-fns.config';
import { StorageService } from '@services/storage.service';
import { DEFAULT_APP_LANGUAGES } from '@configs/constants.config';
import { de, enUS, pl } from 'date-fns/locale';

export const dateFnsConfigurationFactory = (storageService: StorageService): () => Promise<void> => {
  const locale = storageService.getUserLanguage() ?? 'en';
  const dateFnsLocale = DEFAULT_APP_LANGUAGES.includes(locale) ? locale : 'en';
  const config = DATE_FNS_CONFIG;

  switch (dateFnsLocale) {
    case 'pl':
      config.locale = pl;
      break;
    case 'de':
      config.locale = de;
      break;
    default:
      config.locale = enUS;
      break;
  }

  setDefaultOptions(config);

  return () => Promise.resolve();
};
