import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { LOGO_PATH, PLACEHOLDER_IMAGE_128x128 } from '@configs/constants.config';
import { TranslocoPipe } from '@jsverse/transloco';
import { VgApiService, VgCoreModule } from '@videogular/ngx-videogular/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ButtonModule } from 'primeng/button';
import { NgIcon, provideIcons } from '@ng-icons/core';
import { AUTH_LAYOUT_CONFIG } from '@layouts/auth-layout/_configs/auth-layout.config';

@Component({
  selector: 'app-auth-layout',
  standalone: true,
  imports: [RouterLink, RouterOutlet, VgCoreModule, TranslocoPipe, LazyLoadImageModule, ButtonModule, NgIcon],
  providers: [provideIcons(AUTH_LAYOUT_CONFIG.icons)],
  templateUrl: './auth-layout.component.html',
  styleUrl: './auth-layout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthLayoutComponent {
  protected readonly PLACEHOLDER_IMAGE_128x128 = PLACEHOLDER_IMAGE_128x128;
  protected readonly LOGO_PATH = LOGO_PATH;

  onVideoReady(event: VgApiService): void {
    event.play();
  }
}
