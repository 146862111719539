import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserRole } from '@enums/user-role.enum';
import { StorageService } from '@services/storage.service';
import { ToastService } from '@services/toast.service';

export const OnlyAdminPageGuard: CanActivateFn = () => {
  const storageService = inject(StorageService);
  const toastService = inject(ToastService);
  const router = inject(Router);

  const isAdmin = storageService.getRole() === UserRole.ADMIN;

  if (!isAdmin) {
    router.navigateByUrl(`/`).then(() => toastService.error('_Errors.You are not authorized to view this page'));
    return false;
  }

  return true;
};
