import { Routes } from '@angular/router';
import { AppRoutes } from '@configs/routes.config';

const TICKETS_DETAILS = () => import('@pages/dashboard/ticket/_pages/ticket-details/ticket-details.component').then(m => m.TicketDetailsComponent);

export const TicketRoutes: Routes = [
  {
    path: AppRoutes.Ticket.Details,
    loadComponent: TICKETS_DETAILS,
    title: 'Ticket details',
  },
  {
    path: AppRoutes.Blank,
    redirectTo: 'inbox',
    pathMatch: 'full',
  },
];
