import { Injectable, computed, effect, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { environment } from '@environment';
import { ticketsCounter } from '@helpers/echo-cache.helper';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({ providedIn: 'root' })
export class TemplatePageTitleStrategy extends TitleStrategy {
  private readonly translocoService = inject(TranslocoService);
  private readonly unreadTicketsCount = computed(() => ticketsCounter());
  private routerState?: RouterStateSnapshot;

  constructor(private readonly title: Title) {
    super();
    this.title.setTitle(`${environment.brand}`);

    effect(() => {
      if (this.unreadTicketsCount() !== null && this.unreadTicketsCount() !== undefined && this.routerState) {
        this.updateTitle(this.routerState);
      }
    });
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    this.routerState = routerState;
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      const pageTitle = `${environment.brand} - ${this.translocoService.translate(`_DashboardLayoutSidebar.${title}`)}`;
      if (this.unreadTicketsCount()) {
        this.title.setTitle(`(${this.unreadTicketsCount()}) ${pageTitle}`);
      } else {
        this.title.setTitle(pageTitle);
      }
    }
  }
}
