import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { DashboardLayoutService } from '@layouts/dashboard-layout/_services/dashboard-layout.service';
import { catchError, throwError } from 'rxjs';

export const authErrorInterceptor: HttpInterceptorFn = (request: HttpRequest<any>, next: HttpHandlerFn) => {
  const dashboardLayoutService = inject(DashboardLayoutService);

  return next(request).pipe(
    catchError(error => {
      if (error instanceof HttpErrorResponse) {
        switch (error.status) {
          case 401:
          case 403:
            dashboardLayoutService.logoutUser();
            break;
        }
      }
      return throwError(() => error);
    })
  );
};
