import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { WindowEvent } from '@enums/window-event.enum';

export const authErrorInterceptor: HttpInterceptorFn = (request: HttpRequest<any>, next: HttpHandlerFn) => {
  return next(request).pipe(
    catchError(error => {
      if (error instanceof HttpErrorResponse) {
        switch (error.status) {
          case 401:
          case 403:
            window.dispatchEvent(new Event(WindowEvent.LOGOUT_USER));
            break;
        }
      }
      return throwError(() => error);
    }),
  );
};
