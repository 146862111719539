import { ticketsCounter } from '@helpers/echo-cache.helper';
import { StorageService } from '@services/storage.service';
import { TicketsService } from '@services/tickets.service';
import { firstValueFrom, tap } from 'rxjs';

export const unreadTicketsCountFactory = (ticketsService: TicketsService, storageService: StorageService): (() => Promise<number | undefined>) => {
  if (!storageService.getAccessToken()) {
    return () => Promise.resolve(undefined);
  }

  return () => {
    return firstValueFrom(ticketsService.getUnreadTicketsCount().pipe(tap((response: number) => ticketsCounter.set(response))));
  };
};
