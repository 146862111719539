import { Route } from '@angular/router';
import { AppRoutes } from '@configs/routes.config';
import { AuthorizedUserGuard } from '@guards/authorized-user.guard';
import { DashboardLayoutComponent } from '@layouts/dashboard-layout/dashboard-layout.component';
import { SettingsRoute } from '@routes/settings.route';
import { AgentRoutes } from './agent.route';
import { AgentsRoutes } from './agents.route';
import { TicketRoutes } from './ticket.route';
import { TicketsRoutes } from './tickets.route';

const DASHBOARD_PAGE = () => import('@pages/dashboard/dashboard/dashboard.component').then(m => m.DashboardComponent);

const TICKETS_PAGE = () => import('@pages/dashboard/tickets/tickets.component').then(m => m.TicketsComponent);

const STATISTICS_PAGE = () => import('@pages/dashboard/statistics/statistics.component').then(m => m.StatisticsComponent);

const TICKET_PAGE = () => import('@pages/dashboard/ticket/ticket-page.component').then(m => m.TicketPageComponent);

const AGENT_PAGE = () => import('@pages/dashboard/agent/agent-page.component').then(m => m.AgentPageComponent);

const AGENTS_PAGE = () => import('@pages/dashboard/agents/agents.component').then(m => m.AgentsComponent);

const TICKETS_NEW_MESSAGE = () => import('@pages/dashboard/new-message/new-message.component').then(m => m.NewMessageComponent);

export const DashboardRoute: Route = {
  path: AppRoutes.Dashboard,
  component: DashboardLayoutComponent,
  canActivate: [AuthorizedUserGuard],
  children: [
    {
      path: AppRoutes.Dashboard,
      loadComponent: DASHBOARD_PAGE,
      title: 'Dashboard',
    },
    {
      path: AppRoutes.Tickets.Root,
      loadComponent: TICKETS_PAGE,
      children: TicketsRoutes,
      title: 'Tickets',
    },
    {
      path: AppRoutes.NewMessage,
      loadComponent: TICKETS_NEW_MESSAGE,
      title: 'New message',
    },
    {
      path: AppRoutes.Statistics,
      loadComponent: STATISTICS_PAGE,
      title: 'Statistics',
    },
    {
      path: `${AppRoutes.Ticket.Root}/${AppRoutes.Ticket.Folder}`,
      loadComponent: TICKET_PAGE,
      children: TicketRoutes,
    },
    {
      path: AppRoutes.Agents.Root,
      loadComponent: AGENTS_PAGE,
      children: AgentsRoutes,
      title: 'Agents',
    },
    {
      path: `${AppRoutes.Agents.Root}/${AppRoutes.Agents.Page}`,
      loadComponent: AGENT_PAGE,
      children: AgentRoutes,
    },
    SettingsRoute,
  ],
};
