import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { UserRole } from '@enums/user-role.enum';
import { StorageService } from '@services/storage.service';
import { ToastService } from '@services/toast.service';

export const EmailFootersPageGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const storageService = inject(StorageService);
  const toastService = inject(ToastService);
  const router = inject(Router);

  const userId = storageService.getUser()?.id;
  const isAgent = storageService.getRole() === UserRole.AGENT;
  const id = Number.parseInt(route.paramMap.get('id') || '');

  if (isAgent && userId !== id) {
    router.navigateByUrl(`/agents/all/${id}/about`).then(() => toastService.error('_Errors.You are not authorized to view this page'));
    return false;
  }

  return true;
};
