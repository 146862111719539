import { DEFAULT_LANGUAGE } from '@configs/constants.config';
import { Translation, TranslocoService } from '@jsverse/transloco';
import { StorageService } from '@services/storage.service';
import { firstValueFrom } from 'rxjs';

export const appLanguageFactory = (translocoService: TranslocoService, storageService: StorageService): (() => Promise<Translation>) => {
  return () => {
    const userLanguage = storageService.getUserLanguage();
    const currentLanguage = userLanguage ?? DEFAULT_LANGUAGE;
    translocoService.setActiveLang(currentLanguage);
    return firstValueFrom(translocoService.load(currentLanguage));
  };
};
