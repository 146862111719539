import { APP_INITIALIZER, LOCALE_ID, Provider } from '@angular/core';
import { TitleStrategy } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { ErrorService } from '@services/error.service';
import { StorageService } from '@services/storage.service';
import { TemplatePageTitleStrategy } from '@services/template-page-title-strategy.service';
import { TicketsService } from '@services/tickets.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { appErrorListFactory } from '../factories/app-error-list.factory';
import { appLanguageFactory } from '../factories/app-language.factory';
import { appTimeZoneFactory } from '../factories/app-time-zone.factory';
import { themeModeProvider } from '../factories/theme-mode.factory';
import { unreadTicketsCountFactory } from '../factories/unread-tickets-count.factory';

export const APP_PROVIDERS: Provider[] = [
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: appLanguageFactory,
    deps: [TranslocoService, StorageService],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: appErrorListFactory,
    deps: [ErrorService],
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: unreadTicketsCountFactory,
    deps: [TicketsService, StorageService],
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: appTimeZoneFactory,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: themeModeProvider,
    deps: [StorageService],
    multi: true,
  },
  {
    provide: LOCALE_ID,
    deps: [TranslocoService],
    useFactory: (translocoService: TranslocoService) => {
      return translocoService.getActiveLang();
    },
  },
  {
    provide: TitleStrategy,
    useClass: TemplatePageTitleStrategy,
  },
  ConfirmationService,
  MessageService,
];
